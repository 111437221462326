<template>
  <div>
    <div class="header-top">
      <div class="left-wrap">
        <div class="header-title">当前学员</div>
        <div class="student-info">
          <img src="https://cdnwukong.com/public/im/user.png" class="student-avtor" />
          <span style="margin-left: 10px">{{ studentObj.name || studentProfile.fullName }}</span>
          <span style="color: rgb(102, 102, 102)">（{{ studentObj.code || studentProfile.code }}）</span>
        </div>
      </div>
      <div class="right-wrap"></div>
    </div>
    <div class="clue-wrap">
      <div class="plan-leftWrap">
        <div class="tag-title">添加学习规划项：</div>
        <div class="tag-wrap">
          <div
            class="tag-item"
            v-for="item in navArr"
            :key="item.value"
            :class="[{ active: selectedTags.indexOf(item.value) > -1 }]"
            @click="selTagFun(item.value, true)"
          >
            {{ item.label }}<a-icon type="plus" />
          </div>
        </div>
      </div>
      <div class="plan-rightWrap">
        <div class="all-title">学员学习规划</div>
        <div>可点击左侧添加学习规划项</div>
        <a-form autocomplete="false" :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }" :form="form">
          <div class="form-wrap">
            <div class="form-title">
              <div class="explain-wrap">
                <span class="title">学情总结</span>
                <a-tooltip>
                  <template slot="title">
                    <div
                      class="task-explain"
                      @click="lookWord('https://wukongedu.feishu.cn/sheets/shtcn4RArFloQOp12yXmORlMgFe')"
                    >
                      中文级别参考文档
                    </div>
                    <div
                      class="task-explain"
                      @click="lookWord('https://wukongedu.feishu.cn/sheets/shtcnPiI5kXIhWxbvnRRs1UYEJf?sheet=025f19')"
                    >
                      数学级别参考文档
                    </div>
                  </template>
                  <i class="explainTC"></i>
                </a-tooltip>
              </div>
            </div>
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item label="科目">
                  <a-select
                    @change="changeSubject"
                    v-decorator="[
                      'learningConclusion.subject',
                      { rules: [{ required: true, message: '请选择科目!' }] },
                    ]"
                    placeholder="请选择科目"
                  >
                    <a-select-option value="CHINESE">中文</a-select-option>
                    <a-select-option value="MATH">数学</a-select-option>
                    <!-- <a-select-option value="ENGLISH">英文</a-select-option> -->

                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="学习时长">
                  <a-input placeholder="请输入" disabled v-decorator="['learningConclusion.learningDay']" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="剩余课时">
                  <a-input placeholder="请输入" disabled v-decorator="['learningConclusion.remainingCu']" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="每周课频">
                  <a-select
                    v-decorator="[
                      'learningConclusion.weekSchedule',
                      { rules: [{ required: true, message: '请选择每周课频!' }] },
                    ]"
                    placeholder="请选择科目"
                  >
                    <a-select-option :value="item" v-for="item in weekScheduleArr" :key="item">{{
                      item
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="出勤率">
                  <a-input
                    placeholder="请输入"
                    v-decorator="[
                      'learningConclusion.attendance',
                      { rules: [{ required: true, message: '请输入出勤率!' }] },
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="作业完成率">
                  <a-input
                    placeholder="请输入"
                    v-decorator="[
                      'learningConclusion.homeworkCompleted',
                      { rules: [{ required: true, message: '请输入作业完成率!' }] },
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="课程类型">
                  <a-select
                    @change="changeCourseType"
                    allow-clear
                    show-search
                    :filter-option="filterOption"
                    v-decorator="[
                      'learningConclusion.subjectTypeId',
                      { rules: [{ required: true, message: '请选择课程类型!' }] },
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option :value="item.id" :key="index" v-for="(item, index) in courseTypeArr">{{
                      item.label
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="课程名称">
                  <a-select
                    @change="changeCourseName"
                    allow-clear
                    show-search
                    :filter-option="filterOption"
                    option-filter-prop="children"
                    v-decorator="[
                      'learningConclusion.courseId',
                      { rules: [{ required: true, message: '请选择课程名称!' }] },
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option :value="i.uuid" v-for="i in courseNameArr" :key="i.uuid">
                      {{ i.courseName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="当前级别">
                  <a-cascader
                    :field-names="{ label: 'name', value: 'code', children: 'items' }"
                    :options="currentLevelArr"
                    placeholder="请选择"
                    v-decorator="[
                      'learningConclusion.currentSectionLevel',
                      { rules: [{ required: true, message: '请选择当前级别!' }] },
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="下个级别">
                  <a-cascader
                    :field-names="{ label: 'name', value: 'code', children: 'items' }"
                    :options="currentLevelArr"
                    @change="handleChangeLevel"
                    placeholder="请选择"
                    v-decorator="[
                      'learningConclusion.nextSectionLevel',
                      { rules: [{ required: true, message: '请选择当前级别!' }] },
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="当前级别重点">
                  <a-textarea
                    v-decorator="['learningConclusion.currentSectionPoint', validatorRules.currentSectionPoint]"
                    placeholder="请输入"
                    :auto-size="{ minRows: 3, maxRows: 10 }"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="下个级别重点">
                  <a-textarea
                    v-decorator="['learningConclusion.nextSectionPoint', validatorRules.nextSectionPoint]"
                    placeholder="请输入"
                    :auto-size="{ minRows: 3, maxRows: 10 }"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row style="padding-left: 20px">
              <a-form-item label="学习建议" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
                <a-textarea
                  v-decorator="['learningConclusion.learningAdvice', validatorRules.learningAdvice]"
                  placeholder="请输入"
                  :auto-size="{ minRows: 3, maxRows: 10 }"
                />
              </a-form-item>
            </a-row>
          </div>
          <div class="form-wrap" v-if="selectedTags.indexOf('classPerformance') > -1">
            <div class="form-title">
              <span class="title"
                >课堂表现<span style="font-weight: normal; font-size: 12px">（最多可选择5节课）</span></span
              ><span class="delete-text" @click="deleteItem('classPerformance')"> <a-icon type="delete" /> 删除</span>
            </div>
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item label="选择课节">
                  <a-select
                    placeholder="请选择"
                    show-search
                    :filter-option="filterOption"
                    option-filter-prop="children"
                    @change="changeCourseSchedule"
                 
                    mode="multiple"
                    v-decorator="['courseScheduleIds', { rules: [{ required: true, message: '请选择课节!' }] }]"
                  >
                    <a-select-option
                      v-for="item in courseScheduleList"
                      :value="item.courseScheduleId"
                      :key="item.courseScheduleId"
                      >{{
                        `${item.courseScheduleRespDTO.scheduleName}(${item.courseScheduleRespDTO.startChinaDateTime})`
                      }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="课节标签">
                  <a-checkbox-group
                    v-decorator="['classLabelList', { rules: [{ required: true, message: '请选择课节标签!' }] }]"
                  >
                    <a-checkbox :value="item.value" v-for="item in classLabelOptions" :key="item.value">{{
                      item.label
                    }}</a-checkbox>
                  </a-checkbox-group>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <div class="form-wrap" v-if="selectedTags.indexOf('learningAdvice') > -1">
            <div class="form-title">
              <span class="title">课程推荐</span
              ><span class="delete-text" @click="deleteItem('learningAdvice')"> <a-icon type="delete" /> 删除</span>
            </div>
            <div class="add-wrap" v-for="(item, index) in topicRecommendedArr" :key="index">
              <div class="add-item">
                <a-row>
                  <a-col :span="11">
                    <a-form-item label="专题课推荐">
                      <a-select
                        allow-clear
                        show-search
                        :filter-option="filterOption"
                        option-filter-prop="children"
                        v-decorator="[
                          `learningAdvice.topicRecommended[${index}].name`,
                          { rules: [{ required: true, message: '请选择专题课推荐!' }] },
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option :value="i.courseName" v-for="i in specialCoursesArr" :key="i.uuid">
                          {{ i.courseName }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="11">
                    <a-form-item label="课程介绍">
                      <a-textarea
                        v-decorator="[
                          `learningAdvice.topicRecommended[${index}].value`,
                          { rules: [{ max: 500, message: '输入内容不能超过500字!' }] },
                        ]"
                        placeholder="请输入"
                        :auto-size="{ minRows: 3, maxRows: 10 }"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>
              <div class="add-iconWrap">
                <a-icon
                  type="minus-circle"
                  class="reduce-icon"
                  v-if="topicRecommendedArr.length > 1"
                  @click="reduceFun(index)"
                />
                <a-icon
                  type="plus-circle"
                  class="add-icon"
                  @click="addFun"
                  v-if="topicRecommendedArr.length === index + 1"
                />
              </div>
            </div>
          </div>
          <div class="form-wrap" v-if="selectedTags.indexOf('learningPathPlan') > -1">
            <div class="form-title">
              <div class="explain-wrap">
                <span class="title">学习路径规划</span>
                <a-tooltip>
                  <template slot="title">
                    <div
                      class="task-explain"
                      @click="lookWord('https://wukongedu.feishu.cn/sheets/shtcnArBxVlmeXPXPe4m8LhZaof?sheet=blAehE')"
                    >
                      中文学习路径规划
                    </div>
                    <div
                      class="task-explain"
                      @click="lookWord('https://wukongedu.feishu.cn/sheets/shtcnhIhJ6hwcGa9XpC52vGCgUb?sheet=025f19')"
                    >
                      数学学习路径规划
                    </div>
                  </template>
                  <i class="explainTC"></i>
                </a-tooltip>
              </div>
              <span class="delete-text" @click="deleteItem('learningPathPlan')"> <a-icon type="delete" /> 删除</span>
            </div>
            <a-row :gutter="23" style="padding-left: 20px">
              <a-form-item label="学习目标" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
                <a-textarea
                  v-decorator="[
                    'learningPathPlan.learningTarget',
                    { rules: [{ max: 500, message: '输入内容不能超过500字!' }] },
                  ]"
                  placeholder="请输入"
                  :auto-size="{ minRows: 3, maxRows: 10 }"
                />
              </a-form-item>
            </a-row>
            <div class="add-wrap" v-for="(item, index) in learningPathArr" :key="index">
              <div class="add-item">
                <a-row>
                  <a-col :span="12">
                    <a-form-item label="学习路径(一类)">
                      <a-select
                        @change="changeLearningPath($event, index)"
                        v-decorator="[
                          `learningPathPlan.learningPath[${index}].filed1`,
                          { rules: [{ required: true, message: '请选择学习路径!' }] },
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="(item, index) in studyPathArr"
                          :value="JSON.stringify(item)"
                          :key="index"
                          >{{ item.structure }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item label="课节数量">
                      <a-input
                        disabled
                        v-decorator="[`learningPathPlan.learningPath[${index}].filed2`]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row style="padding-left: 20px">
                  <a-form-item label="等级学习收获" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
                    <a-textarea
                      v-decorator="[
                        `learningPathPlan.learningPath[${index}].filed3`,
                        {
                          rules: [
                            { required: true, message: '请填写等级学习收获!' },
                            { max: 500, message: '输入内容不能超过500字!' },
                          ],
                        },
                      ]"
                      placeholder="请输入"
                      :auto-size="{ minRows: 3, maxRows: 10 }"
                    />
                  </a-form-item>
                </a-row>
              </div>

              <div class="add-iconWrap">
                <a-icon
                  type="minus-circle"
                  class="reduce-icon"
                  v-if="learningPathArr.length > 1"
                  @click="reducePathFun(index)"
                />
                <a-icon
                  type="plus-circle"
                  class="add-icon"
                  @click="addPathFun"
                  v-if="learningPathArr.length === index + 1"
                />
              </div>
            </div>
          </div>
          <div class="btn-wrap">
            <a-button style="margin-right: 25px" @click="hrefStudent">取消</a-button>
            <a-button @click="previewFun" type="primary">预览</a-button>
          </div>
        </a-form>
      </div>
    </div>
    <a-modal
      title="报告详情"
      :okButtonProps="{
        props: {
          loading: iconLoading,
        },
      }"
      :visible="visibleReport"
      @ok="handleSubmit"
      okText="生成并复制"
      cancelText="关闭"
      width="426px"
      @cancel="onCancelReport"
    >
      <div class="modal-height">
        <div class="studyReportTemplate2TC" ref="studyReportTemplateTC">
          <learningPlanningReport
            :studyData="studyData"
            v-if="visibleReport"
            :subject="subject"
          ></learningPlanningReport>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import html2canvas from 'html2canvas';
import { imgpostUrl } from '@/api/register';

import {
  getSubjectCourse,
  queryConclusion,
  queryCourseType,
  queryCourseLevelList,
  queryPerformanceData,
  querySchedulingList,
  createStudyPlan,
  queryStudyPath,
  querySectionNum,
  getUploadOssToken,
  getCourseScheduleList,
} from '@/api/headTeacher';
import learningPlanningReport from './learningPlanningReport.vue';

const session = storage.createStore(sessionStorage);

export default {
  name: 'learningPlanning',
  components: { learningPlanningReport },
  data() {
    return {
      courseScheduleList: [],
      validatorRules: {
        learningAdvice: {
          rules: [
            { required: true, message: '请输入学习建议!' }, // 此处开启校验必填
            { max: 500, message: '输入内容不能超过500字!' }, // 长度校验
          ],
          trigger: 'change', // 触发方式
        },
        currentSectionPoint: {
          rules: [
            { required: true, message: '请输入当前级别重点!' },
            { max: 500, message: '输入内容不能超过500字!' },
          ],
          trigger: 'change',
        },
        nextSectionPoint: {
          rules: [
            { required: true, message: '请输入下个级别重点!' },
            { max: 500, message: '输入内容不能超过500字!' },
          ],
          trigger: 'change',
        },
      },
      studentObj: {},
      iconLoading: false,
      uploadUrl: '',
      studyData: {},
      visibleReport: false,
      courseId: '',
      studyPathArr: [],
      specialCoursesArr: [],
      performanceObj: [],
      courseNameArr: [],
      courseTypeArr: [],
      weekScheduleArr: ['0次', '1次', '2次', '3次', '4次', '5次', '6次', '7次', '8次', '9次', '10次'],
      userInfo: storage.get('userInfo'),
      subject: '',
      courseArr: [],
      currentLevelArr: [],
      topicRecommendedArr: [1],
      learningPathArr: [1],
      classLabelOptions: [
        { label: '课堂正确率', value: 'classCorrect' },
        { label: '作业正确率', value: 'homeworkCorrect' },
        { label: '获得奖励次数', value: 'award' },
      ],
      classLabelList: [],
      form: this.$form.createForm(this),
      selectedTags: [],
      selVal: 'classPerformance',
      navArr: [
        { label: '课堂表现', value: 'classPerformance' },
        { label: '学习建议', value: 'learningAdvice' },
        { label: '学习路径规划', value: 'learningPathPlan' },
      ],
      studentProfile: session.get('studentProfile'),
    };
  },
  methods: {
    changeCourseSchedule(val) {
      console.log(val);
      if (val.length > 5) {
        this.$message.warning('最多可选择5节课');
        val.pop();
      }
    },
    getCourseScheduleList(subject) {
      const params = {
        statuses: ['COMPLETED', 'ABSENT'],
        studentId: this.studentProfile.uuid,
        pageSize: 50,
        subject,
      };
      getCourseScheduleList(params).then((res) => {
        this.courseScheduleList = res.data.content;
      });
    },

    hrefStudent() {
      const h = this.$createElement;
      const that = this;
      this.$confirmAnt({
        title: '操作提示',
        content: h(
          'div',
          {
            style: {
              textAlign: 'left',
            },
          },
          [
            h(
              'div',
              {
                style: {
                  marginBottom: '10px',
                },
              },
              '当前记录还未提交',
            ),
            h(
              'div',
              {
                style: {
                  fontSize: '14px',
                  color: '#999',
                },
              },
              '注意：关闭后，清空数据',
            ),
          ],
        ),
        okText: '仍要关闭',
        cancelText: '不关闭',
        class: 'cancel-clue',
        onOk() {
          that.$router.push({
            path: '/studentDetail',
            query: { uuid: that.studentObj.studentId || that.studentProfile.uuid },
          });
        },
        onCancel() {},
      });
    },
    lookWord(linkUrl) {
      window.open(linkUrl, '_blank');
    },
    handleSubmit() {
      this.iconLoading = true;
      this.$nextTick(() => {
        // const cvs = document.createElement('canvas');
        // const dpr = window.devicePixelRatio || 1;
        // cvs.width = this.$refs.studyReportTemplateTC.offsetWidth * dpr;
        // cvs.height = this.$refs.studyReportTemplateTC.offsetHeight * dpr;
        // // //修改window电脑图片模糊问题
        //   cvs.style.width = this.$refs.studyReportTemplateTC.offsetWidth + 'px';
        //   cvs.style.height =  this.$refs.studyReportTemplateTC.offsetHeight+ 'px';
        //   cvs.getContext('2d').scale(dpr,dpr)

        html2canvas(this.$refs.studyReportTemplateTC, {
          useCORS: true, // 【重要】开启跨域配置
          scale: 2, // 按比例增加分辨率 (2=双倍).
          dpi: window.devicePixelRatio * 2, // 设备像素比
          allowTaint: true, // 允许跨域图片
        }).then((canvas) => {
          const dataURL = canvas.toDataURL('image/png', 1.0);
          const params = {
            imgData: dataURL,
          };
          this.sendStudyReport(params);
          // 复制成功

          canvas.toBlob(async (blob) => {
            const data = [
              new ClipboardItem({
                [blob.type]: blob,
              }),
            ];
            await navigator.clipboard.write(data);
            this.$message.success('复制报告成功');
          });
        });
      });
    },
    sendStudyReport(formData) {
      const blob = this.convertBase64UrlToBlob(formData.imgData);
      const file = this.blobToFile(blob, 'studyReport.jpg');
      getUploadOssToken().then((res) => {
        this.uploadToOss(file, res.data.content, formData);
      });
    },
    // 将base64的图片转换为file文件
    convertBase64UrlToBlob(urlData) {
      const bytes = window.atob(urlData.split(',')[1]); // 去掉url的头，并转换为byte
      // 处理异常,将ascii码小于0的转换为大于0
      const ab = new ArrayBuffer(bytes.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i += 1) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], { type: 'image/jpeg' });
    },
    // 将blob转换为file
    blobToFile(theBlob, fileName) {
      return new File([theBlob], fileName, { type: 'image/jpeg', lastModified: Date.now() });
    },
    uploadToOss(info, fileData, oldFormData) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info);
      imgpostUrl(`https://${fileData.endpoint}`, formData).then((res) => {
        const newFormData = oldFormData;
        this.uploadUrl = res.data.url;
        this.createStudyPlan();
      });
    },
    handleChangeLevel(value, selectedOptions) {
      console.log(selectedOptions);
    },
    onCancelReport() {
      this.visibleReport = false;
    },

    changeCourseName(val) {
      this.courseId = val;
    },
    // 获取课节数量
    querySectionNum(obj, index) {
      const params = {
        courseId: this.courseId,
        courseStructure: {
          subjectType: obj.parentValue,
          level1: obj.value,
        },
      };
      querySectionNum(params).then((res) => {
        const count = res.data.content;
        const { learningPathPlan } = this.form.getFieldsValue();
        learningPathPlan.learningPath[index].filed2 = count;
        this.form.setFieldsValue({ learningPathPlan });
      });
    },
    changeLearningPath(value, index) {
      const obj = JSON.parse(value);
      this.querySectionNum(obj, index);
    },
    // 获取学习路径
    queryStudyPath(subjectTypeId) {
      const params = {
        parentId: subjectTypeId,
      };
      queryStudyPath(params).then((res) => {
        this.studyPathArr = res.data.content;
      });
    },

    // 获取专题课程下拉列表
    querySchedulingList() {
      const params = {
        subject: this.subject,
        shelfStatus: ['ON'],
        courseType: 'STANDARD',
      };

      querySchedulingList(params).then((res) => {
        this.specialCoursesArr = res.data.content;
      });
    },
    changeCourseType(val) {
      this.queryStudyPath(val);
      this.queryCourseLevelList(val);
    },
    // 获取课程级别下拉列表
    queryCourseLevelList(courseId) {
      const params = {
        parentId: courseId,
      };
      queryCourseLevelList(params).then((res) => {
        this.currentLevelArr = res.data.content;
      });
    },
    changeSubject(val) {
      this.subject = val;
      // 课节标签数据
      // this.queryPerformanceData();
      // 获取课程类型
      this.queryCourseType();
      this.queryConclusion();
      // 获取课程名称
      this.getSubjectCourse();
      // 获取专题课程推荐
      this.querySchedulingList();
      this.form.setFieldsValue({
        courseScheduleIds: [],
      });

      this.getCourseScheduleList(val);
    },
    // 或缺学情总结
    queryConclusion() {
      const params = {
        studentId: this.studentObj.studentId || this.studentProfile.uuid,
        subject: this.subject,
      };
      queryConclusion(params).then((res) => {
        console.log(res);
        const data = res.data.content;
        this.courseId = data.courseId || '';
        this.form.setFieldsValue({
          learningConclusion: {
            subject: data.subject,
            learningDay: data.learningDay,
            remainingCu: data.remainingCu,
            weekSchedule: data.weekSchedule,
            homeworkCompleted: data.homeworkCompleted,
            attendance: data.attendance,
            subjectTypeId: data.subjectTypeId || undefined,
            courseId: data.courseId || undefined,
            currentSectionLevel: data.currentSectionLevel && [
              data.currentSectionLevel.level1,
              data.currentSectionLevel.level2,
            ],
            currentSectionPoint: data.currentSectionPoint,
            nextSectionLevel: data.nextSectionLevel && [data.nextSectionLevel.level1, data.nextSectionLevel.level2],
            nextSectionPoint: data.nextSectionPoint,
          },
        });
        // 初始级别下拉列表，是根据课程类型
        if (data.subjectTypeId) {
          this.changeCourseType(data.subjectTypeId);
        }
      });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
    // 获取课程名称
    getSubjectCourse(val) {
      const params = {
        subject: this.subject, // 科目
        shelfStatus: ['ON'],
      };
      getSubjectCourse(params).then((res) => {
        this.courseNameArr = res.data.content;
      });
    },
    reducePathFun(index) {
      this.learningPathArr = this.learningPathArr.filter((i, idx) => {
        return index !== idx;
      });
      const { learningPathPlan } = this.form.getFieldsValue();
      learningPathPlan.learningPath.splice(index, 1);
      this.form.setFieldsValue({ learningPathPlan });
    },
    addPathFun() {
      this.learningPathArr.push(1);
    },
    reduceFun(index) {
      console.log(index);

      this.topicRecommendedArr = this.topicRecommendedArr.filter((i, idx) => {
        return index !== idx;
      });
      const { learningAdvice } = this.form.getFieldsValue();
      learningAdvice.topicRecommended.splice(index, 1);
      this.form.setFieldsValue({ learningAdvice });
    },
    addFun() {
      this.topicRecommendedArr.push(1);
    },

    previewFun() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.selectedTags.indexOf('classPerformance') > -1) {
            queryPerformanceData(this.studentProfile.uuid, {
              courseScheduleIds: values.courseScheduleIds,
            }).then((res) => {
              this.performanceObj = res.data.content;
              // 课堂表现，标签数据处理
              const classPerformance = {};
              if (values.classLabelList.length > 0) {
                values.classLabelList.forEach((item) => {
                  classPerformance[item] = this.performanceObj[item];
                });
              }
              values.classPerformance = classPerformance;
              this.initStudyFun(values);
            });
          } else {
            this.form.setFieldsValue({
              classLabelList: [],
            });
            this.initStudyFun(values);
          }
        }
      });
    },
    initStudyFun(values) {
      values.learningConclusion.currentSectionLevel = {
        level1: this.initOptionsLevel(values.learningConclusion.currentSectionLevel[0], this.currentLevelArr),
        level2: values.learningConclusion.currentSectionLevel[1],
      };
      values.learningConclusion.nextSectionLevel = {
        level1: this.initOptionsLevel(values.learningConclusion.nextSectionLevel[0], this.currentLevelArr),
        level2: values.learningConclusion.nextSectionLevel[1],
      };
      this.studyData = values;
      this.visibleReport = true;
    },
    // 生成报告
    createStudyPlan() {
      // 生成报告
      const params = {
        subject: this.subject,
        url: this.uploadUrl, // 图片的URL
        studentId: this.studentProfile.uuid,
        studentCode: this.studentProfile.code,
        studentName: this.studentProfile.fullName,
        studyPlan: this.studyData,
      };
      createStudyPlan(params)
        .then((res) => {
          this.iconLoading = false;
          this.$message.success('报告生成成功');
          this.$router.push({ path: '/learningPlanningHistory' });
        })
        .catch(() => {
          this.iconLoading = false;
        });
    },
    selTagFun(tag, checked) {
      this.selVal = tag;
      const { selectedTags } = this;
      const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
      this.selectedTags = nextSelectedTags;
    },
    // 删除元素
    deleteItem(val) {
      console.log(val);
      this.selectedTags = this.selectedTags.filter((item) => {
        return item !== val;
      });
    },
    queryCourseType() {
      const params = {
        subject: this.subject,
      };
      queryCourseType(params).then((res) => {
        this.courseTypeArr = res.data.content;
      });
    },
    queryPerformanceData(courseScheduleIds) {
      const params = {
        courseScheduleIds,
      };
      queryPerformanceData(this.studentProfile.uuid, params).then((res) => {
        this.performanceObj = res.data.content;
      });
    },
    initOptionsLevel(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.code === value) {
          name = item.name;
        }
      });

      return name;
    },
  },
  created() {
    if (this.$route.query.studentObj) {
      this.studentObj = JSON.parse(this.$route.query.studentObj);
    }
    this.subject = this.studentObj.subject || this.userInfo.subject;

    if (this.subject) {
      this.queryConclusion();
      this.queryCourseType();
      this.getSubjectCourse();
      // 课节标签数据
      // this.queryPerformanceData();
      // 获取专题课程下拉列表
      this.querySchedulingList();
      this.getCourseScheduleList(this.subject);
    }
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.explainTC {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('~@/assets/explain.png') no-repeat center;
  background-size: 100%;
  margin-left: 4px;
  margin-top: 2px;
  cursor: pointer;
}
.explain-wrap {
  display: flex;
  align-items: center;
}
.header-top {
  padding: 18px 20px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.header-title {
  font-size: 16px;
  font-weight: 600;
}
.student-avtor {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.student-info {
  font-size: 16px;
  color: #333;
  margin-top: 5px;
}
.operator-btn {
  color: #1f2d3d;
  font-size: 13px;
  padding: 5px 8px;
  margin-left: 10px;
  border: 1px solid rgba(31, 45, 61, 0.18);
  cursor: pointer;
}
.clue-wrap {
  display: flex;
  min-height: 80vh;
}
.plan-leftWrap {
  padding: 18px 20px 0;
  background-color: #fff;
  margin-right: 10px;
  width: 200px;
}
.plan-rightWrap {
  padding: 18px 20px 0;
  background-color: #fff;
  flex: 1;
}
.tag-item {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  padding: 5px 15px;
  background: #f3f3f3;
  border-radius: 5px;
  cursor: pointer;
}
.tag-title {
  font-size: 16px;
  margin-bottom: 12px;
}
.active {
  background-color: #e6fff4;
}
.all-title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 12px;
}
.form-wrap {
  padding: 12px 20px;
  background-color: #fafafa;
  margin-top: 20px;
}
.form-title {
  width: 100%;
  display: flex;

  justify-content: space-between;
  font-size: 16px;
  color: #333;
  margin-bottom: 12px;
  .title {
    font-size: 16px;
    font-weight: 600;
    &::before {
      content: '';
      display: block;

      float: left;
      margin: 4px 6px 0 0;
      width: 3px;
      height: 16px;
      background-color: #41cc83;
      border-radius: 0px 2px 2px 0px;
    }
  }
}
/deep/ .ant-form-item {
  margin-bottom: 18px;
}
.btn-wrap {
  margin: 20px 0;
  text-align: center;
}
.add-item {
  background: #fafafa;
  border-radius: 4px;
  padding: 20px 0 0;
  margin-bottom: 16px;
  flex: 1;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.1);
}
.reduce-icon {
  font-size: 24px;

  margin-left: 5px;
  margin-top: 25px;
  cursor: pointer;
}
.add-icon {
  font-size: 24px;
  color: #04cb94;
  margin-top: 25px;
  margin-left: 5px;
  cursor: pointer;
}
.delete-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.modal-height {
  height: 508px;
  overflow-y: auto;
}
.studyReportTemplate2TC {
  position: relative;
  z-index: 23;
  right: 0px;
  width: 375px;
  background: transparent;
}
.task-explain {
  cursor: pointer;
  margin-bottom: 5px;
}
.add-wrap {
  display: flex;

  justify-content: space-between;
}
.add-iconWrap {
  width: 65px;
}
</style>
