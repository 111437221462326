<template>
  <div class="poster-planwrap" :class="subject === 'CHINESE' ? 'poster-chinese' : ' poster-math'">
    <div class="poster-bgwrap">
      <div class="title" v-if="subject === 'CHINESE'">悟空中文学习规划</div>
      <div class="title" v-else>悟空数学学习规划</div>
      <div class="poster-unit">{{ $moment(new Date()).format('YYYY年MM月DD日') }}</div>
    </div>
    <div class="eval-wrap" style="margin-top: -30px">
      <div class="pic-wrap">
        <div class="monkey"></div>
        <div class="pic-txt">
          <div class="student-name">{{ studentProfile.fullName }}</div>
          <div class="student-desc">
            悟空{{subject === 'CHINESE'?'中文':'数学'}}已经陪伴您度过了<span class="date-color">{{ studyData.learningConclusion.learningDay }}</span
            >
          </div>
        </div>
      </div>
      <div class="data-wrap">
        <div class="data-item">
          <div class="data-txt">{{ studyData.learningConclusion.weekSchedule }}</div>
          <div class="data-txt1">周课时频率</div>
        </div>
        <div class="line"></div>
        <div class="data-item">
          <div class="data-txt">{{ studyData.learningConclusion.attendance }}</div>
          <div class="data-txt1">{{ subject === 'CHINESE' ? '中文' : '数学' }}出勤率</div>
        </div>
        <div class="line"></div>
        <div class="data-item">
          <div class="data-txt">{{ studyData.learningConclusion.homeworkCompleted }}</div>
          <div class="data-txt1">作业完成率</div>
        </div>
      </div>
      <div class="suggest-desc">
         <pre style="white-space:pre-wrap;">{{studyData.learningConclusion.learningAdvice}}</pre>
      </div>
      <div class="class-wrap">
        <img src="@/assets/newImage/pic_head_teacher.png" class="teacher-pic" />
        <span class="grey-txt">{{ subject === 'CHINESE' ? '中文班主任' : '数学辅导老师' }}：</span>
        <span>{{ this.userInfo.fullName }}</span>
      </div>
    </div>
    <div class="eval-wrap" v-if="studyData.classPerformance">
      <div class="eval-title" style="padding:22px 0 0">
        <img v-if="subject === 'CHINESE'" src="@/assets/newImage/report-h1.png" class="report-h1" />
        <img v-else src="@/assets/newImage/report-h1-math.png" class="report-h1" />
      </div>
      <div class="echart-wrap">
        <div id="echart"></div>
      </div>
      <div class="table-wrap">
        <a-table :columns="columns" size="small" :pagination=false :data-source="tableData">
            <span slot="name" slot-scope="name">
              <span class="tag-icon classCorrect" v-if="name==='classCorrect'">
              </span>
              <span class="tag-icon homeworkCorrect" v-if="name==='homeworkCorrect'">
              </span>
               <span class="tag-icon award" v-if="name==='award'">
              </span>
           </span>
        </a-table>
        <div class="tag-desc">
          <div v-if="showClassCorrect">
             <span class="tag-icon classCorrect">
              </span>
              课堂正确率
          </div>
          <div v-if="showHomeWorkCorrect">
            <span class="tag-icon homeworkCorrect">

            </span>
            作业正确率
          </div>
            <div  v-if="showAward">
               <span class="tag-icon award">
              </span>
              获得奖励次数
            </div>
        </div>
      </div>
    </div>
    <div class="eval-wrap">
      <div class="eval-title">
        <img v-if="subject === 'CHINESE'" src="@/assets/newImage/report-h2.png" class="report-h2" />
        <img v-else src="@/assets/newImage/report-h2-math.png" class="report-h2" />
      </div>
      <div class="header-top">
        <div class="sub-title">
          当前阶段:{{
            `${studyData.learningConclusion.currentSectionLevel.level1}-${studyData.learningConclusion.currentSectionLevel.level2}`
          }}
        </div>
      </div>
      <div class="sub-desc sub-desc-color">
          <pre style="white-space:pre-wrap;">{{studyData.learningConclusion.currentSectionPoint}}</pre>
      
      </div>
      <div class="arrow-wrap">
        <img v-if="subject === 'CHINESE'" src="@/assets/newImage/guide-icon.png" class="arrow-icon" />
        <img v-else src="@/assets/newImage/guide-icon-math.png" class="arrow-icon" />
      </div>
      <div class="header-top1">
        <div class="sub-title1">
          下个阶段:
          {{
            `${studyData.learningConclusion.nextSectionLevel.level1}-${studyData.learningConclusion.nextSectionLevel.level2}`
          }}
        </div>
      </div>
      <div class="sub-desc1">
          <pre style="white-space:pre-wrap;">{{studyData.learningConclusion.nextSectionPoint}}</pre>
      
      </div>
    </div>
    <div class="eval-wrap" v-if="topicRecommended.length > 0">
      <div class="eval-title">
        <img v-if="subject === 'CHINESE'" src="@/assets/newImage/report-h3.png" class="report-h3" />
        <img v-else src="@/assets/newImage/report-h3-math.png" class="report-h3" />
      </div>
      <div class="course-wrap" v-for="(item, index) in topicRecommended" :key="index">
        <div class="course-title">
          <span class="course-subtitle">{{ item.name }}</span>
        </div>
        <div class="course-desc">
          <pre style="white-space:pre-wrap;">{{item.value}}</pre>

        </div>
      </div>
    </div>
    <div class="eval-wrap" v-if="studyData.learningPathPlan">
      <div class="eval-title">
        <img v-if="subject === 'CHINESE'" src="@/assets/newImage/report-h4.png" class="report-h4" />
        <img v-else src="@/assets/newImage/report-h4-math.png" class="report-h4" />
      </div>
      <div class="desc-txt">
           <pre style="white-space:pre-wrap;">{{studyData.learningPathPlan.learningTarget}}</pre>
      </div>
      <div class="time-wrap">
        <a-timeline>
          <a-timeline-item
            v-for="(item, index) in learningPath"
            :key="index"
            :color="subject === 'CHINESE' ? 'rgba(255, 102, 102, 1)' : 'rgba(0, 156, 255, 1)'"
          >
            <div class="time-title">
              <span class="time-subtitle">{{ JSON.parse(item.filed1).label }}</span>
              <span class="time-grey">({{ item.filed2 }}课节)</span>
            </div>
            <div class="time-desc">
            
            <pre style="white-space:pre-wrap;">{{item.filed3}}</pre>

            </div>
          </a-timeline-item>
        </a-timeline>
      </div>
    </div>
    <div class="logo-wrap">
      <img v-if="subject === 'CHINESE'" src="@/assets/newImage/startpage_logo.png" class="logo-pic" />
      <img v-else src="@/assets/newImage/startpage_logo_math.png" class="logo-pic" />
    </div>

  </div>
</template>

<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';

const session = storage.createStore(sessionStorage);

export default {
  name: 'learningPlanningReport',
  props: {
    subject: {
      type: String,
      default: 'MATH',
    },
    studyData: {
      type: Object,
    },
  },
  data() {
    return {
      showHomeWorkCorrect:false,
      showAward:false,
      showClassCorrect:false,
      homeworkCorrectData:[],
       classLabelOptions: [
        { label: '课堂正确率', value: 'classCorrect' },
        { label: '作业正确率', value: 'homeworkCorrect' },
        { label: '获得奖励次数', value: 'award' },
      ],
      labelData:[],
      awardData:[],
      homeworkCorrect:[],
      classCorrectData:[],
      classPerformance:{},
      columns:[
        {
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  
      ],
      tableData:
        [ 
           
            
        ],
      learningPath: [],
      topicRecommended: [],
      userInfo: storage.get('userInfo'),
      myChart: null, // 定义变量用来存放echarts实例
      studentProfile: session.get('studentProfile'),
    };
  },
 
  methods: {

    drawEcharts() {
      //处理数据
        let legendData=[]
     Object.keys(this.classPerformance).forEach((key) => {
      this.classLabelOptions.forEach((item)=>{
        if(item.value===key){
          this.labelData.push(item.label)
        }
      })
        if(key==='homeworkCorrect'){
          this.showHomeWorkCorrect=true
          this.classPerformance.homeworkCorrect.forEach((item)=>{
             this.homeworkCorrectData.push(item.filed3)
             legendData.push(item.filed1)
          })
         
        }
          if(key==='classCorrect'){
            this.showClassCorrect=true
          this.classPerformance.classCorrect.forEach((item)=>{
             this.classCorrectData.push(item.filed3)
             legendData.push(item.filed1)
          })
         
        }
          if(key==='award'){
            this.showAward=true
          this.classPerformance.award.forEach((item)=>{
             this.awardData.push(item.filed3)
             legendData.push(item.filed1)
          })
         
        }
       if(key==='classCorrect'){
              this.tableData.push({
              key: '1',
              name: 'classCorrect',
              series1:`${this.classCorrectData[0]}%`,
              series2:`${this.classCorrectData[1]}%`,
              series3:`${this.classCorrectData[2]}%`,
              series4:`${this.classCorrectData[3]}%`,
              series5:`${this.classCorrectData[4]}%`,
          })
           }
        
           if(key==='homeworkCorrect'){
              this.tableData.push({
              key: '2',
              name: 'homeworkCorrect',
              series1:`${this.homeworkCorrectData[0]}%`,
              series2:`${this.homeworkCorrectData[1]}%`,
              series3:`${this.homeworkCorrectData[2]}%`,
              series4:`${this.homeworkCorrectData[3]}%`,
              series5:`${this.homeworkCorrectData[4]}%`,
          })
           }
         if(key==='award'){
             this.tableData.push({
              key: '3',
              name: 'award',
              series1:this.awardData[0],
              series2:this.awardData[1],
              series3:this.awardData[2],
              series4:this.awardData[3],
              series5:this.awardData[4],
          })
         }
    })
        this.legendData =[...new Set(legendData)]
        this.legendData.forEach((item,index)=>{
          this.columns.push(
             {
            title:item,
            dataIndex:`series${index+1}`,
            key:item,
             }
          )
      
        })
         
           
      
      const chartDom = document.getElementById('echart');
      this.myChart = this.$echarts.init(chartDom);
      let intervalCount=2;
      let maxCount=10;
if(this.awardData.length>0){
var max=this.awardData.reduce((max, item) => {  
        return max > item ? max : item}     
    )
    intervalCount=Math.round(max/5)
    maxCount=intervalCount*5

}
    
      let option;
      const colors = ['#45BCFF', '#FFC929', '#C496FF'];
      option = {
         color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },

        // legend: {
        //   data:this.labelData,
        // },
        xAxis: [
          {
            type: 'category',
            data:this.legendData,
            axisPointer: {
              type: 'shadow',
            },
          },
        ],
        yAxis: [
        
          {
            type: 'value',
            name: '',
            min: 0,
            max:100,
            interval:20,
            axisLabel: {
              formatter: '{value} %',
            },
          },

          {
            type: 'value',
            name: '',
            min: 0,
            max:maxCount,
            interval:intervalCount,
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        series: [
          {
            name: '课堂正确率',
            type: 'bar',
            tooltip: {
              valueFormatter(value) {
                return `${value} %`;
              },
            },
            data:this.classCorrectData,
          },

          {
            name: '作业正确率',
            type: 'bar',
            tooltip: {
              valueFormatter(value) {
                return `${value} %`;
              },
            },
            data:this.homeworkCorrectData,
          },
          {
            name: '获得奖励次数',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter(value) {
                return `${value} 次`;
              },
            },
            data:this.awardData,
          },
        ],
      };
      option && this.myChart.setOption(option);
    },
  },

  mounted() {
  
    this.topicRecommended = (this.studyData.learningAdvice && this.studyData.learningAdvice.topicRecommended) || [];
    this.learningPath = (this.studyData.learningPathPlan && this.studyData.learningPathPlan.learningPath) || [];
    this.classPerformance=this.studyData.classPerformance || {}
    console.log( this.classPerformance)
    // 第二步，在页面加载渲染的时候执行echarts画图方法
    this.drawEcharts();
  },
};
</script>

<style lang="less">

.poster-planwrap {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  position: relative;
  width: 375px;
  margin: 0 auto;
  border-radius: 8px;
  padding-top: 192px;
  font-family: Helvetica, Arial, sans-serif;

  overflow: hidden;
.tag-desc{
  font-size: 12px;
   justify-content: space-around;
    align-items: center;
    display: flex;
    margin-top:12px;
    
}
.tag-desc>div{
 
    align-items: center;
    display: flex;
}
  .award{
    background: #C496FF;
  }
  .tag-icon{
  width: 12px;
height: 12px;
border-radius: 2px;
display: inline-block;
  }
  .classCorrect{
    background: #45BCFF;
  }
  .homeworkCorrect{
    background: #FFC929;
  }
  .table-wrap{
    padding:0 12px;
  }
  .echart-wrap {
  
 
  }
  #echart {
    width:400px;
    height:380px;
    transform: scale(0.85);
    position: relative;
    left: -20px;
  }
  
  .title {
    color: #ffffff;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 30px;
    left: 26px;
  }
  .poster-bgwrap {
    position: absolute;
    width: 375px;
    height: 400px;
    background: url('../../assets/newImage/pic_top_bg.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .poster-unit {
    color: #fff;
    font-size: 16px;
    position: absolute;
    left: 26px;
    top: 85px;
    padding: 5px 12px;
    border-radius: 22px;
    border: 1px solid #fff;
  }
  .eval-wrap {
    margin: 12px 15px 16px;
    background: #ffffff;
    border-radius: 30px;
    position: relative;
    padding-bottom: 30px;
  }
  .monkey {
    width: 52px;
    height: 52px;
    background: url('../../assets/newImage/pic_head_student.png') no-repeat;
    background-size: 100%;
    margin-right: 12px;
  }
  .pic-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 17px;
  }
  .pic-txt {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.4);
  }
  .student-name {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    margin-bottom: 6px;
    font-weight: bold;
  }
  .line {
    width: 1px;
    height: 40px;

    background-color: #eeeeee;
    border-radius: 0px 2px 2px 0px;
  }
  .data-wrap {
    background: #f9f9f9;
    border-radius: 12px;
    display: flex;
    justify-content: space-around;
    padding: 16px 0;
    margin: 20px 16px;
  }
  .data-txt {
    font-size: 24px;

    font-weight: bold;
    text-align: center;
  }
  .data-txt1 {
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    text-align: center;
  }
  .suggest-desc {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    padding: 0 16px;
  }
  .teacher-pic {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .class-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-top: 16px;
  }
  .grey-txt {
    color: rgba(0, 0, 0, 0.4);
  }
  .eval-title {
    padding: 22px 0;
    text-align: center;
  }
  .report-h1 {
    width: 116px;
    height: 30px;
  }
  .report-h2 {
    width: 139px;
    height: 30px;
  }
  .report-h3 {
    width: 120px;
    height: 30px;
  }
  .report-h4 {
    width: 120px;
    height: 30px;
  }

  .sub-title {
    border-radius: 0px 20px 20px 0px;
    padding: 6px 12px;
    color: #fff;
    font-size: 16px;
    position: absolute;
    left: 0px;
    top: 12px;
  }
  .sub-desc {
    padding: 12px;

    font-size: 15px;

    margin: 0 16px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .header-top1 {
    width: 313px;
    height: 60px;

    margin: 0 auto;
    position: relative;
  }
  .sub-title1 {
    border-radius: 0px 20px 20px 0px;
    padding: 6px 12px;

    font-size: 16px;
    position: absolute;
    left: 0px;
    top: 12px;
  }
  .sub-desc1 {
    padding: 0px 12px 12px;
    color: rgb(255, 255, 255);
    font-size: 15px;
    background: rgb(255, 102, 102);
    margin: -1px 0 0 20px;
    width: 309px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .arrow-wrap {
    text-align: center;
    margin: 8px 0;
  }
  .arrow-icon {
    width: 28px;
    height: 28px;
  }
  .course-wrap {
    padding: 0 16px 16px;
  }
  .course-title {
    width: 313px;
    height: 53px;

    display: flex;
    align-items: center;
  }
  .course-desc {
    padding: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    background: #f9f9f9;
    width: 313px;
  }
  .course-subtitle {
    padding-left: 33px;
    font-size: 18px;
    color: #fff;
  }
  .desc-txt {
    font-size: 16px;
    padding: 0 16px;
    margin-bottom: 20px;
  }
  .time-wrap {
    padding: 0 16px;
  }
  .time-grey {
    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    padding-left: 5px;
  }
  .time-subtitle {
    font-size: 20px;
    font-weight: bold;
  }
  .time-desc {
    padding: 12px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 15px;
    background: #f9f9f9;
    border-radius: 12px;
    margin-top: 5px;
  }
  .logo-wrap {
    text-align: center;
    padding: 0px 0px 15px;
  }
  .logo-pic {
    width: 100px;
    height: 35px;
  }
}
.poster-chinese {
  background: rgba(255, 102, 102, 0.1);
  .poster-bgwrap {
    position: absolute;
    width: 375px;
    height: 400px;
    background: url('../../assets/newImage/pic_top_bg.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .data-txt {
    color: #ff5353;
  }
  .date-color {
    color: #ff5353;
  }
  .sub-titleBg {
    background: #d89191;
  }
  .sub-desc-color {
    color: #d89191;
    background: #fff6f6;
  }
  .header-top {
    width: 313px;
    height: 44px;
    background: url('../../assets/newImage/header-top.png') no-repeat;
    background-size: 100%;
    margin: 0 auto;
    position: relative;
  }
  .sub-title {
    background: #d89191;
  }
  .header-top1 {
    background: url('../../assets/newImage/header-top1.png') no-repeat;
    background-size: 100%;
  }
  .sub-desc1 {
    background: #ff6666;
  }
  .sub-title1 {
    background: #fff2f2;
    color: #ff6666;
  }
  .course-title {
    background: url('../../assets/newImage/center-bg.png') no-repeat;
    background-size: 100%;
  }
}
.poster-math {
  background: rgba(38, 171, 255, 0.1);
  .poster-bgwrap {
    position: absolute;
    width: 375px;
    height: 400px;
    background: url('../../assets/newImage/pic_top_bg_math.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .data-txt {
    color: #009cff;
  }
  .date-color {
    color: #009cff;
  }
  .sub-desc-color {
    color: #55a5d8;
    background: #f2faff;
  }
  .sub-titleBg {
    background: #55a5d8;
  }
  .sub-title1-color {
    background: #26abff;
    color: #26abff;
  }
  .header-top {
    width: 313px;
    height: 44px;
    background: url('../../assets/newImage/header-top-math.png') no-repeat;
    background-size: 100%;
    margin: 0 auto;
    position: relative;
  }
  .sub-title {
    background: #55a5d8;
  }

  .header-top1 {
    background: url('../../assets/newImage/header-top1-math.png') no-repeat;
    background-size: 100%;
  }
  .sub-desc1 {
    background: #26abff;
  }
  .sub-title1 {
    background: #ecf8ff;
    color: #26abff;
  }
  .course-title {
    background: url('../../assets/newImage/center-bg-math.png') no-repeat;
    background-size: 100%;
  }
}
</style>
